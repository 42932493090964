import * as React from "react"
import PropTypes from "prop-types"

import Navi from "./footer-navi"

const Footer = ({ division }) => (
  <footer>
    {division !== '' && <Navi division={division} />}
  </footer>
)

Footer.propTypes = {
  division: PropTypes.string,
}

Footer.defaultProps = {
  division: ``,
}

export default Footer
