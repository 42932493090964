import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import * as Styles from "./footer-navi.module.scss"

import imgLogoGyosei from "../images/common/circle_gyosei.png"
import imgLogoSupport from "../images/common/circle_support.png"
import iconContact from "../images/common/contact.svg"
import iconCharge from "../images/common/charge.svg"
import iconAccess from "../images/common/access.svg"

const Navi = ({ division }) => {

  const DATA = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            divisionJa { gyosei, support },
            menuJa {contact, charge, access}
          }
        }
      }
    `
  )

  const GYOSEI_JA = DATA.site.siteMetadata.divisionJa.gyosei
  const SUPPORT_JA = DATA.site.siteMetadata.divisionJa.support
  const CONTACT_JA = DATA.site.siteMetadata.menuJa.contact
  const CHARGE_JA = DATA.site.siteMetadata.menuJa.charge
  const ACCSESS_JA = DATA.site.siteMetadata.menuJa.access

  // 基本は行政書士事務所
  let divisionJa = GYOSEI_JA
  let divisionStyles = Styles.gyosei
  let imgTop = imgLogoGyosei
  let otherDivision = 'support'
  let otherDivisionJa = SUPPORT_JA
  let imgOtherDivision = imgLogoSupport
  let bgColor = `bg-gyosei_color`

  // サポートURLの場合はオフィス
  if(division === 'support') {
    divisionJa = SUPPORT_JA
    divisionStyles = Styles.support
    imgTop = imgLogoSupport
    otherDivision = 'gyosei'
    otherDivisionJa = GYOSEI_JA
    imgOtherDivision = imgLogoGyosei
    bgColor = `bg-support_color`
  }

  let linkTop   = <Link to={`/${division}`} className={`${Styles.list} ${divisionStyles}`}><img src={imgTop} alt={divisionJa} />TOP</Link>
  let linkContact = <Link to={`/${division}/contact`} className={`${Styles.list} ${divisionStyles}`}><img src={iconContact} alt={CONTACT_JA} />{CONTACT_JA}</Link>
  let linkCharge = <Link to={`/${division}/charge`} className={`${Styles.list} ${divisionStyles}`}><img src={iconCharge} alt={CHARGE_JA} />{CHARGE_JA}</Link>
  let linkAccess = <Link to={`/${division}/access`} className={`${Styles.list} ${divisionStyles}`}><img src={iconAccess} alt={ACCSESS_JA} />{ACCSESS_JA}</Link>
  let linkOther = <Link to={`/${otherDivision}`} className={`${Styles.list} ${Styles.gyosei}`}><img src={imgOtherDivision} alt={otherDivisionJa} /></Link>
  let pcLinkContact = <Link to={`/${division}/contact`} className={`${Styles.list}`}>{CONTACT_JA}</Link>
  let pcLinkCharge = <Link to={`/${division}/charge`} className={`${Styles.list}`}>{CHARGE_JA}</Link>
  let pcLinkAccess = <Link to={`/${division}/access`} className={`${Styles.list}`}>{ACCSESS_JA}</Link>
  let pcLinkOther = <Link to={`/${otherDivision}`} className={`${Styles.list}`}>{otherDivisionJa}</Link>

  return (
    <div>
      <nav className={`${Styles.nav} ${bgColor}`}>
        <ul className={Styles.sp_menu}>
          <li className="w-full">{linkTop}</li>
          <li className="w-full">{linkContact}</li>
          <li className="w-full">{linkCharge}</li>
          <li className="w-full">{linkAccess}</li>
          <li className="w-full">{linkOther}</li>
        </ul>
      </nav>
      <div className={`${Styles.pc_footer} ${bgColor}`}>
        <div className={Styles.content}>{pcLinkContact}</div>
        <div className={Styles.content}>{pcLinkCharge}</div>
        <div className={Styles.content}>{pcLinkAccess}</div>
        <div>{pcLinkOther}</div>
      </div>
    </div>
  )
}
export default Navi
