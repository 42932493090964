/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ division, children }) => {
  const DATA = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="w-screen lg:container lg:mx-auto">
      <Header siteTitle={DATA.site.siteMetadata?.title || `Title`} division={division} />
      <main className={division !== '' && "mt-12 pb-20 sm:pb-0"}>
        {children}
      </main>
      <Footer division={division} />
    </div>
  )
}

Layout.defaultProps = {
  division: ``,
}

Layout.propTypes = {
  division: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
