import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ServiceNoneLink from "../../components/service-none-link"

import * as Styles from "../styles/index.module.scss"

import iconIt from "../../images/support/it.svg"
import iconWebsite from "../../images/support/website.svg"
import iconOfficework from "../../images/support/officework.svg"
import iconSupport from "../../images/support/support.svg"
import iconFace from "../../images/common/harukawa.png"


const IndexPage = () => {

  const DATA = useStaticQuery(
    graphql`
      query {
        site { 
          siteMetadata { 
            divisionJa { support }
          }
        }
      }
    `
  )
  
  const SUPPORT_JA = DATA.site.siteMetadata.divisionJa.support

  return(
    <Layout division='support'>
      <Seo title={SUPPORT_JA} />
      <div id={Styles.support} className={Styles.appeal}>
        <StaticImage
          src="../../images/common/circle_support.png"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt={SUPPORT_JA}
          className={Styles.appeal_icon}
        />
      </div>

      <div className="flex flex-wrap justify-evenly my-8">
        <ServiceNoneLink
          division='support'
          icon={iconIt}
          title="IT導入支援"
          desc={[
            '日々繰り返す作業に追われていませんか。ITの技術やサービスを利用することで、業務の負担を減らせるかもしれません。お客様の状況・環境やご希望をお伺いし、適した技術やサービスを調査いたします。ご希望に応じて導入のお手伝い、その後の継続支援も承ります。'
          ]}
          target={[
            '日々繰り返す作業を楽にしたい',
            'ITを取り入れたいが方法がわからない',
            '気になるIT技術/サービスがある',
            '取り入れた技術/サービスがうまく使えない',
          ]}
        />

        <ServiceNoneLink
          division='support'
          icon={iconWebsite}
          title="WEBサイト"
          desc={[
            'プログラマーの経験を活かして、スマートフォンにも対応したWEBサイトの作成・管理等を承ります。すでにお持ちのWEBサイトのリニューアルや、管理の引継ぎ等もいたします。お気軽にお問い合わせください。'
          ]}
          target={[
            '集客にインターネットを使いたい',
            'WEBサイトを作りたい',
            'WEBサイトを新しくしたい',
            'WEBサイトの管理が大変',
          ]}
        />

        <ServiceNoneLink
          division='support'
          icon={iconOfficework}
          title="事務代行"
          desc={[
            'おつかいや事務作業でお休みの日がつぶれていませんか。可能な部分をお任せいただいて、プライベートな時間を作りませんか。幣所の他事業と併せてご利用いただけば、さらにお得に承れます。ぜひご検討ください。'
          ]}
          target={[
            '月に数度のおつかいがある',
            '休みの日に事務仕事をしている',
            'もっと事業に専念したい',
            '事務要員を雇う余裕はない',
          ]}
        />

        <ServiceNoneLink
          division='support'
          icon={iconSupport}
          title="事業主サポート"
          desc={[
            '事業主にはやること・決めることがたくさんあって大変です。少しだけお手伝いしたり、お困りごとを伺って整理したりすることで、良くなることがあるかもしれません。よろしればお話をお聞かせください。'
          ]}
          target={[
            '少しだけ手伝ってほしいことがある',
            '仕事で困っていることがある',
            '新しいことに挑戦したい',
            'とりあえず話を聞いてほしい',
          ]}
        />
      </div>
      <div className={Styles.greeting}>
          <div className={Styles.support}>
              <h1 className={Styles.index}>ようこそオフィス白玉へ</h1>
              <div className={Styles.comment}>
                  <img src={iconFace} />
                  <p>
                      代表の春川里佳です。ご覧いただきありがとうございます。<br />
                      事業や家庭の事務作業を楽にしたい方、WEBサイトを作りたい方、WEBサイトの管理にお困りの方、できるだけ丁寧にお手伝いさせていただきます。<br />
                      電話やオンライン通話でも対応いたします。まずはお気軽にお問い合わせください。
                  </p>
              </div>
              <div className={Styles.contact_button}>
                  <Link to="/support/contact"><button>お問い合わせ</button></Link>
              </div>
          </div>
      </div>
    </Layout>
  )
}

export default IndexPage
