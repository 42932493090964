import * as React from "react"
import PropTypes from "prop-types"

import * as Styles from "./service.module.scss"

import iconCheckGyosei from "../images/gyosei/check.svg"
import iconCheckSupport from "../images/support/check.svg"

const ServiceNoneLink = ({ division, to, icon, title, desc, target }) => {
    let bgColor = `bg-gyosei_subcolor`
    let borderColor = `border-gyosei_subcolor`
    let checkIcon = iconCheckGyosei
    let contentHeight = `${Styles.gyosei}`

    if (division === 'support') {
        bgColor = `bg-support_subcolor`
        borderColor = `border-support_subcolor`
        checkIcon = iconCheckSupport
        contentHeight = `${Styles.support}`
    }

    return (
        <div className={`${Styles.service_content} ${borderColor} ${contentHeight}`}>
            <h2 className={bgColor}><img src={icon} alt="" />{title}</h2>
            <p>
            {desc.map ((node) => (
                <span key={node}>{node}</span>
            ))}
            </p>
            <h3 className={`${borderColor} ${bgColor}`}>こんな方におすすめ</h3>
            <ul className={borderColor}>
            {target.map ((node) => (
                <li key={node}><img src={checkIcon} alt="" />{node}</li>
            ))}
            </ul>
        </div>
    )
}
ServiceNoneLink.propTypes = {
    division: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.array,
    target: PropTypes.array,
}

ServiceNoneLink.defaultProps = {
    division: ``,
    icon: ``,
    title: ``,
    desc: [],
    target: [],
}

export default ServiceNoneLink


