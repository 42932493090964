// extracted by mini-css-extract-plugin
export var section = "index-module--section--egTkv";
export var contents = "index-module--contents--2lB3D";
export var description = "index-module--description--2XluC";
export var logo = "index-module--logo--2SNul";
export var gyosei = "index-module--gyosei--2rD0-";
export var support = "index-module--support--1ZUmV";
export var appeal = "index-module--appeal--2UTNx";
export var appeal_icon = "index-module--appeal_icon--15X_t";
export var greeting = "index-module--greeting--1BI0m";
export var index = "index-module--index--27gOx";
export var comment = "index-module--comment--XM8eV";
export var contact_button = "index-module--contact_button--BwFCU";